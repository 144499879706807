import React from 'react';
import { Link } from 'gatsby';

import { BaseTemplate } from '@templates';
import { SEO, PageSection } from '@components';

import './index.scss';

const IndexPage = () => (
  <BaseTemplate>
    <SEO title="List of Third Parties" />
    <PageSection className="subpage-third-parties">
      <div className="third-parties-container">
        <div className="sidebar display-none-is-mobile">
          <h4 className="text-sh700 sidebar-header">Other documents</h4>
          <ul className="section-title-list">
            <li className="sidebar-item">
              <Link className="sidebar-link" to="/terms">Terms of Use</Link>
            </li>
            <li className="sidebar-item">
              <Link className="sidebar-link" to="/privacy">Privacy Policy</Link>
            </li>
            <li className="sidebar-item">
              <Link className="sidebar-link" to="/restricted-businesses">Restricted Businesses</Link>
            </li>
          </ul>
        </div>

        {/* TODO: could be separated per section */}
        <div className="content">
          <div className="title">
            <h2>List of Third Parties</h2><br />
            <h4>Last updated: April 10, 2020</h4>
          </div>

          <div className="section">
            <p>
              To support PayMongo in delivering its Services, PayMongo may engage third party service providers
              to assist PayMongo with its data processing activities.
            </p>
            <p>
              This page identifies the third party service providers, describes where they are located, and what
              services they provide to us. Before engaging any third party, we perform extensive due diligence,
              including detailed security and legal analysis. We do not engage a third party unless our quality
              standards are met. Our third parties are all subject to contract terms that enforce compliance
              with applicable data protection laws.
            </p>
            <p>PayMongo currently uses the following third parties:</p>

            <table>
              <thead>
                <tr>
                  <th>THIRD PARTY</th>
                  <th>SERVICE PROVIDED</th>
                  <th>LOCATION OF THIRD PARTY</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Airtable</td>
                  <td>Spreadsheet, data storage</td>
                  <td>United States of America</td>
                </tr>
                <tr>
                  <td>Amazon Web Services</td>
                  <td>Cloud service provider</td>
                  <td>United States of America</td>
                </tr>
                <tr>
                  <td>DCPay</td>
                  <td>Coins.ph acquiring, OTC processing</td>
                  <td>Philippines</td>
                </tr>
                <tr>
                  <td>Dropbox</td>
                  <td>File storage, document sharing</td>
                  <td>United States of America</td>
                </tr>
                <tr>
                  <td>HubSpot</td>
                  <td>User relationship management</td>
                  <td>United States of America</td>
                </tr>
                <tr>
                  <td>Intercom</td>
                  <td>User relationship management</td>
                  <td>United States of America</td>
                </tr>
                <tr>
                  <td>Mynt</td>
                  <td>GCash acquiring</td>
                  <td>Philippines</td>
                </tr>
                <tr>
                  <td>Google</td>
                  <td>Email, file storage</td>
                  <td>United States of America</td>
                </tr>
                <tr>
                  <td>GPay Network</td>
                  <td>GrabPay acquiring</td>
                  <td>Philippines</td>
                </tr>
                <tr>
                  <td>UnionBank of the Philippines</td>
                  <td>Visa & Mastercard acquiring</td>
                  <td>Philippines</td>
                </tr>
              </tbody>
            </table>
            <p>
              Our business needs may change from time to time. For example, we may deprecate a third party
              to consolidate and minimize our use of third party. Similarly, we may add a third party if
              we believe that doing so will enhance our ability to deliver our Services. We will periodically
              update this page to reflect additions and removals to our list of third parties.
            </p>
            <p>
              For more information on PayMongo’s privacy practices, please consult
              our <Link to="/privacy">Privacy Policy</Link>.
            </p>
          </div>
        </div>
      </div>
    </PageSection>
  </BaseTemplate>
);

export default IndexPage;
